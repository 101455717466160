import {
  C2cConversionEvent,
  ConversionEvents,
  ErrorEvents,
  GeneralEvents,
  SYSTEM_ID,
} from '@/utils/constants/datadog'
import { mpActions } from '@/components/Datadog/utils'
import { ORDER_INSTRUCTION_TYPE } from '@/utils/constants/enums'
import { COLLECTION_METHOD } from '@/utils/constants/collectionMethod'
import { IDatadogEventCreationSource } from '@/types/commons/constants'

export const datadogTrackUserDetails = (
  userId: string,
  notificationSource: string
) => {
  mpActions.identify(userId)
  mpActions.track(GeneralEvents.VISITED_PAGE.name, {
    [GeneralEvents.VISITED_PAGE.props.NOTIFICATION_SOURCE]: notificationSource,
  })
  mpActions.people.set({
    [GeneralEvents.VISITED_PAGE.props.SYSTEM_ID]: `sns_${userId}`,
  })
}

export const datadogTrackSelfHelp = (option: string, trackingId: string) => {
  mpActions.track(ConversionEvents.SELF_HELP.name, {
    [ConversionEvents.SELF_HELP.props.OPTION]: option,
    [ConversionEvents.SELF_HELP.props.TRACKING_ID]: trackingId,
  })
}

export const datadogTrackErrorPage = () => {
  mpActions.track(ErrorEvents.ERROR_PAGE.name)
}

export const datadogTrackFailedData = (option: string) => {
  mpActions.track(ErrorEvents.FAILED_VALIDATION.name, {
    [ErrorEvents.FAILED_VALIDATION.props.FLOW]: option,
  })
}

export const datadogTrackRadioSelect = (selectedRadio: string) => {
  mpActions.track(ConversionEvents.SAFE_PLACE_RADIO.name, {
    [ConversionEvents.SAFE_PLACE_RADIO.props.OPTION]: selectedRadio,
  })
}

export const datadogTrackSubmit = (option: string) => {
  switch (option) {
    case ORDER_INSTRUCTION_TYPE.SAFE_PLACE:
      mpActions.track(ConversionEvents.SAFE_PLACE_SUBMIT.name)
      break
    case ORDER_INSTRUCTION_TYPE.NEIGHBOUR:
      mpActions.track(ConversionEvents.NEIGHBOUR_SUBMIT.name)
      break
    case ORDER_INSTRUCTION_TYPE.PHONE:
      mpActions.track(ConversionEvents.PHONE.name)
      break
    case ORDER_INSTRUCTION_TYPE.DOORBELL:
      mpActions.track(ConversionEvents.DOORBELL.name)
      break
    case ORDER_INSTRUCTION_TYPE.ADDRESS:
      mpActions.track(ConversionEvents.ADDRESS.name)
      break
  }
}

export const datadogC2CTrackVisitor = (userId: string) => {
  mpActions.identify(userId)
  mpActions.people.set({
    [GeneralEvents.VISITED_PAGE.props.USER_ID]: userId,
  })
  mpActions.track(GeneralEvents.VISITED_PAGE.name, {
    [GeneralEvents.VISITED_PAGE.props.PAGE_NAME]: 'C2C',
    [GeneralEvents.VISITED_PAGE.props.SYSTEM_ID]: SYSTEM_ID.SG,
  })
}

export const datadogC2CTrackOption = (collectionMethod: string) => {
  mpActions.track(C2cConversionEvent.COLLECTION_METHOD.name, {
    [C2cConversionEvent.COLLECTION_METHOD.props.OPTION_SELECTED]:
      COLLECTION_METHOD[collectionMethod].title,
  })
}

export const datadogC2CTrackOptionClick = () => {
  mpActions.track(C2cConversionEvent.COLLECTION_METHOD_NEXT.name)
}

export const datadogC2CTrackCreationSource = (
  event: IDatadogEventCreationSource,
  creationSource: string
) => {
  mpActions.track(event.name, {
    [event.props.CREATION_SOURCE]: creationSource,
  })
}

export const datadogC2CTrackPaymentError = (creationSource: string) => {
  mpActions.track(C2cConversionEvent.ERROR_PAYMENT.name, {
    [C2cConversionEvent.ERROR_PAYMENT.props.PAYMENT_ERROR_FLOW]:
      'Payment Error',
    [C2cConversionEvent.ERROR_PAYMENT.props.CREATION_SOURCE]: creationSource,
  })
}

export const datadogC2CTrackPaymentSuccess = (creationSource: string) => {
  mpActions.track(C2cConversionEvent.SUCCESSFUL_PAYMENT.name, {
    [C2cConversionEvent.SUCCESSFUL_PAYMENT.props.PAYMENT_SUCCESS_FLOW]:
      'Payment Successful',
    [C2cConversionEvent.SUCCESSFUL_PAYMENT.props.CREATION_SOURCE]:
      creationSource,
  })
}
