import React from 'react'
import { Form, Input } from 'antd'
import { RadioTitle, StyledFormItem } from './styles'
import { ORDER_INSTRUCTION_TYPE } from '@/utils/constants/enums'
import { IAppendOrderInstructionRequest } from '@/types/hooks/order'
import { SubmitButton } from '@/utils/styles'
import { datadogTrackSubmit } from '@/components/Datadog/helpers'
import { removeEmojis } from '@/utils/functions/removeEmoji'

const { TextArea } = Input

type SafePlaceProps = {
  token: string
  isSubmitting: boolean
  appendOrderInstruction: (
    data: IAppendOrderInstructionRequest,
    token: string
  ) => void
}

const SafePlace = ({
  token,
  isSubmitting,
  appendOrderInstruction,
}: SafePlaceProps) => {
  const [form] = Form.useForm()

  const handleAppendInstruction = async () => {
    try {
      await form.validateFields()
      const values = form.getFieldsValue()

      const dataToPost = {
        type: ORDER_INSTRUCTION_TYPE.SAFE_PLACE,
        instruction: `${removeEmojis(`${values.additionalInstruction}`)}`,
      }

      datadogTrackSubmit(ORDER_INSTRUCTION_TYPE.SAFE_PLACE)
      appendOrderInstruction(dataToPost, token)
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Form name='safePlaceInstruction' form={form} layout='vertical'>
      <div>
        Leave your parcel at a safe place that is freely accessible by our
        driver if you&apos;re unable to receive it in person. Please note that
        it is illegal to leave items in dry/wet riser and that our
        drivers/riders will not leave the parcel in the riser even if you have
        requested for it.
      </div>
      <RadioTitle>Leave the parcel in</RadioTitle>
      <StyledFormItem
        name='type'
        noStyle
        initialValue={ORDER_INSTRUCTION_TYPE.SAFE_PLACE}
      >
        <Input hidden />
      </StyledFormItem>
      <StyledFormItem
        name='additionalInstruction'
        initialValue=''
        rules={[{ required: true, message: 'Please enter a location.' }]}
      >
        <TextArea
          showCount
          maxLength={140}
          style={{ paddingTop: '16px' }}
          rows={3}
        />
      </StyledFormItem>
      <SubmitButton
        key='submit'
        type='primary'
        loading={isSubmitting}
        onClick={handleAppendInstruction}
        block
      >
        Submit
      </SubmitButton>
    </Form>
  )
}

export default SafePlace
