import React from 'react'
import { Col, Form, Input, Row, Space } from 'antd'
import { ORDER_INSTRUCTION_TYPE } from '@/utils/constants/enums'
import { InputHeader, SubmitButton } from '@/utils/styles'
import { IAppendOrderInstructionRequest } from '@/types/hooks/order'
import { datadogTrackSubmit } from '@/components/Datadog/helpers'
import { removeEmojis } from '@/utils/functions/removeEmoji'

const { TextArea } = Input

type NeighbourProps = {
  token: string
  isSubmitting: boolean
  appendOrderInstruction: (
    data: IAppendOrderInstructionRequest,
    token: string
  ) => void
}

const Neighbour = ({
  token,
  isSubmitting,
  appendOrderInstruction,
}: NeighbourProps) => {
  const [form] = Form.useForm()

  const handleAppendInstruction = async () => {
    try {
      await form.validateFields()
      const values = form.getFieldsValue()

      const dataToPost = {
        type: ORDER_INSTRUCTION_TYPE.NEIGHBOUR,
        instruction: `${removeEmojis(
          `${values.person} - ${values.contact}, ${values.description}`
        )}
        `,
      }

      datadogTrackSubmit(ORDER_INSTRUCTION_TYPE.NEIGHBOUR)
      appendOrderInstruction(dataToPost, token)
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Form name='leaveWithNeighbour' form={form} layout='vertical'>
      <Space direction='vertical' size='middle' style={{ width: '100%' }}>
        <div>
          Leave your parcel with a concierge or neighbour who lives on the same
          storey.
        </div>
        <div>You will be notified when the parcel has been delivered</div>
        <InputHeader>Person to deliver to</InputHeader>
        <Form.Item
          name='person'
          style={{ marginBottom: '0.5rem' }}
          rules={[
            { required: true, message: 'Please enter a name to deliver to.' },
          ]}
        >
          <Input />
        </Form.Item>
        <Row justify='space-between' align='middle'>
          <Col>
            <InputHeader>Contact Number</InputHeader>
          </Col>
          <Col>Optional</Col>
        </Row>
        <Form.Item name='contact' style={{ marginBottom: '0.5rem' }}>
          <Input />
        </Form.Item>
        <InputHeader>How to find the person</InputHeader>
        <div>
          Type in any helpful details for our delivery instructions (.e.g, unit,
          floor number, etc.)
        </div>
        <Form.Item
          name='description'
          rules={[
            {
              required: true,
              message:
                'Please enter helpful details for our delivery instruction.',
            },
          ]}
        >
          <TextArea showCount maxLength={140} rows={3} />
        </Form.Item>
      </Space>
      <SubmitButton
        key='submit'
        type='primary'
        loading={isSubmitting}
        onClick={handleAppendInstruction}
        block
      >
        Submit
      </SubmitButton>
    </Form>
  )
}

export default Neighbour
