import Config from '@/config'
import {
  IAppendOrderInstructionRequest,
  IChangeOrderAddressRequest,
  ISubmitCsatRatingRequest,
} from '@/types/hooks/order'
import {
  IPaymentIntentRequest,
  IValidateMerchantApplePayRequest,
} from '@/types/hooks/delivery'
import { COUNTRY_CODES } from '@/utils/constants/enums'
import dataUtils from '@/utils/dataUtils'
import apiSauce from 'apisauce'
import { IAppendDeliveryInstructionRequest } from '@/types/hooks/badAddress'
import { ICheckPromoValidRequest } from '@/types/hooks/promo'

let country = ''

if (typeof window !== 'undefined') {
  country = window.location.hash.split('#')[1]
  !country && (country = COUNTRY_CODES.SINGAPORE)
  if (
    window.location.pathname === '/' &&
    !Object.values(COUNTRY_CODES).includes(country as COUNTRY_CODES)
  ) {
    window.location.href = '/error'
  }
}

const consigneeApi = apiSauce.create({
  baseURL: `${Config.BASE_URL}/${country}/consignee`,
})

consigneeApi.addResponseTransform((response) => {
  response.data = dataUtils.toCamelCase(response.data)
})

// READ
const getOrder = (token: string) => consigneeApi.get(`/orders?t=${token}`)

// CREATE
const appendInstruction = (
  data: IAppendOrderInstructionRequest,
  token: string
) => consigneeApi.post(`/orders/append-instruction?t=${token}`, data)

const changeOrderAddress = (data: IChangeOrderAddressRequest, token: string) =>
  consigneeApi.post(
    `/orders/change-address?t=${token}`,
    dataUtils.toSnakeCase(data)
  )

const submitCsatRating = (data: ISubmitCsatRatingRequest, token: string) =>
  consigneeApi.post(`/ratings/submit?t=${token}`, dataUtils.toSnakeCase(data))

const updatePaymentStatus = (c2cId: string) =>
  consigneeApi.get(`/payments/status?c2c_id=${c2cId}`)

const retryFailedPayment = (c2cId: string) =>
  consigneeApi.post(`/payments/retry?c2c_id=${c2cId}`)

const createPaymentIntent = (data: IPaymentIntentRequest) =>
  consigneeApi.post(`/payments`, dataUtils.toSnakeCase(data))

// APIs used in only C2C v2
const checkTidValid = (hashedTid: string) =>
  consigneeApi.get(`/c2c/packs?id=${hashedTid}`)

const submitCsatRatingV2 = (data: ISubmitCsatRatingRequest) =>
  consigneeApi.post('/c2c/ratings/submit', dataUtils.toSnakeCase(data))

// APIs used in bad address
const validateBAHash = (t: string) =>
  consigneeApi.get(`/1.0/bad-address/validate-hash?t=${t}`)

const appendDeliveryInstructions = (data: IAppendDeliveryInstructionRequest) =>
  consigneeApi.post(`/1.0/bad-address/update`, dataUtils.toSnakeCase(data))

const validateMerchantApplePay = (data: IValidateMerchantApplePayRequest) =>
  consigneeApi.post(`/2c2p/applepay/validate`, dataUtils.toSnakeCase(data))

const getAllPrices = () => consigneeApi.get(`/pricing`)

const checkPromoValid = (data: ICheckPromoValidRequest) =>
  consigneeApi.post('/c2c/promo-codes/check', dataUtils.toSnakeCase(data))

export default {
  getOrder,
  appendInstruction,
  changeOrderAddress,
  submitCsatRating,
  createPaymentIntent,
  updatePaymentStatus,
  retryFailedPayment,
  checkTidValid,
  submitCsatRatingV2,
  validateBAHash,
  appendDeliveryInstructions,
  validateMerchantApplePay,
  getAllPrices,
  checkPromoValid,
}
