import React from 'react'
import { DeliveryImg, OptionText, StyledRow } from './styles'
import { StyledCol } from '@/utils/styles'
import { Space, Divider } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import { useRouter } from 'next/router'
import { DELIVERY_TYPE } from '@/utils/constants/deliveryOptions'
import { DELIVERY_INSTRUCTIONS } from '@/utils/constants/enums'
import {
  datadogTrackErrorPage,
  datadogTrackSelfHelp,
} from '@/components/Datadog/helpers'

type DeliveryOptionsProps = {
  option: string
  image: string
  eligibleReason: string
  granularStatus: string
  changeAddressReason: string
  trackingId: string
  setIsModalVisible: (isVisible: boolean) => void
  setModalTitle: (title: string) => void
  setDeliveryType: (type: string) => void
}

const DeliveryOptions = ({
  option,
  image,
  eligibleReason,
  granularStatus,
  changeAddressReason,
  trackingId,
  setIsModalVisible,
  setModalTitle,
  setDeliveryType,
}: DeliveryOptionsProps) => {
  const router = useRouter()

  const checkIfEligible = () => {
    datadogTrackSelfHelp(option, trackingId)

    if (
      option === DELIVERY_INSTRUCTIONS.ADDRESS &&
      changeAddressReason !== 'ok'
    ) {
      datadogTrackErrorPage()
      router.push(
        {
          pathname: '/error',
          query: {
            status: granularStatus,
            reason: changeAddressReason,
            option,
          },
        },
        '/error'
      )
    } else if (eligibleReason === 'ok') {
      setModalTitle(option)
      setDeliveryType(DELIVERY_TYPE[option])
      setIsModalVisible(true)
    } else {
      datadogTrackErrorPage()
      router.push(
        {
          pathname: '/error',
          query: { status: granularStatus },
        },
        '/error'
      )
    }
  }

  return (
    <StyledCol>
      <StyledRow
        justify='space-between'
        align='middle'
        onClick={checkIfEligible}
      >
        <StyledCol span={23}>
          <Space size={24}>
            <DeliveryImg src={image} alt={option} />
            <OptionText>{option}</OptionText>
          </Space>
        </StyledCol>
        <StyledCol span={1}>
          <RightOutlined style={{ stroke: 'black', strokeWidth: '50' }} />
        </StyledCol>
      </StyledRow>
      <Divider />
    </StyledCol>
  )
}

export default DeliveryOptions
