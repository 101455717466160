import { STATUSES, GRANULAR_STATUS, BA_STATUSES } from './enums'

export const STATUS_LABELS = {
  [STATUSES.INVALID]: {
    title: 'This link does not work',
    subtitle: 'The link you clicked has either expired or is invalid',
    logo: '/ninja-stop-sign.svg',
  },
  [STATUSES.SUCCESS]: {
    title: 'Your new instruction is set!',
    subtitle:
      'We will try our best to fulfil your selected delivery option and seek your kind understanding if the option is unfulfilled. Optimisation is underway for us to serve you better. Thank you!',
    logo: 'ninja-success.svg',
  },
  [STATUSES.SUCCESS_ADDRESS]: {
    title: 'Your address has been updated!',
    subtitle: 'Your parcel will be delivered to ',
    subtitle2:
      'Changes to the delivery address will result in an additional 1-3 working days to the delivery date',
    logo: 'ninja-success.svg',
  },
  [STATUSES.ERROR]: {
    title: 'Something went wrong, please try again later',
    subtitle: '',
    logo: 'ninja-stop-sign.svg',
  },
  [STATUSES.INELIGIBLE]: {
    title: 'Unable to set delivery instruction.',
    subtitle:
      'Sorry, your order is ineligible for this option because your order is ',
    logo: 'ninja-stop-sign.svg',
  },
  [STATUSES.LATE]: {
    title: "Sorry, it's too late",
    subtitle:
      'Your parcel is out for delivery! Please make the necessary changes before the scheduled delivery date for your future parcels',
    logo: 'ninja-stop-sign.svg',
  },
  [STATUSES.FAILURE]: {
    title: 'Unable to change delivery address',
    subtitle:
      'Sorry, your order is ineligble for this option because your order is ',
    logo: 'ninja-stop-sign.svg',
  },
  [STATUSES.RTS]: {
    title: 'cwp_unable_to_change_delivery_address',
    subtitle: 'cwp_update_unsuccessful_rts_subtitle',
    logo: 'ninja-stop-sign.svg',
  },
}

export const ineligibleStatuses: Array<string> = [
  GRANULAR_STATUS.CANCELLED,
  GRANULAR_STATUS.COMPLETED,
  GRANULAR_STATUS.ON_HOLD,
  GRANULAR_STATUS.RTS,
]

// Bad Address Status Labels uses lokalise ids
export const BA_STATUS_LABELS = {
  [BA_STATUSES.END_STATE]: {
    title: 'bad_address_status_end_state_title',
    subtitle: 'bad_address_status_end_state_subtitle',
    logo: '/ninja-stop-sign.svg',
  },
  [BA_STATUSES.RTS_TRIGGERED]: {
    title: 'bad_address_status_rts_triggered_title',
    subtitle: 'bad_address_status_rts_triggered_subtitle',
    logo: '/ninja-stop-sign.svg',
  },
  [BA_STATUSES.RETURN_ORDER]: {
    title: 'bad_address_status_return_order_title',
    subtitle: 'bad_address_status_return_order_subtitle',
    logo: '/ninja-stop-sign.svg',
  },
  [BA_STATUSES.UPDATED_BEFORE]: {
    title: 'bad_address_status_end_state_title',
    subtitle: 'bad_address_status_end_state_subtitle',
    logo: '/ninja-stop-sign.svg',
  },
  [BA_STATUSES.UPDATE_UNSUCCESSFUL]: {
    title: 'bad_address_status_update_unsuccessful_title',
    logo: '/ninja-stop-sign.svg',
  },
  [BA_STATUSES.UPDATE_SUCCESSFUL]: {
    title: 'bad_address_status_update_successful_title',
    subtitle: 'bad_address_status_update_successful_subtitle',
    logo: '/ninja-success.svg',
  },
}

export const baStatuses: Array<string> = [
  BA_STATUSES.END_STATE,
  BA_STATUSES.RTS_TRIGGERED,
  BA_STATUSES.RETURN_ORDER,
  BA_STATUSES.UPDATED_BEFORE,
  BA_STATUSES.UPDATE_UNSUCCESSFUL,
  BA_STATUSES.UPDATE_SUCCESSFUL,
]

export const baErrorStatuses: Array<string> = [
  BA_STATUSES.END_STATE,
  BA_STATUSES.RTS_TRIGGERED,
  BA_STATUSES.RETURN_ORDER,
  BA_STATUSES.UPDATED_BEFORE,
  BA_STATUSES.UPDATE_UNSUCCESSFUL,
]
